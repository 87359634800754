<template>
  <div class="home news-bj">
    <div class="news-HImg">
      <img src="@/assets/images/1551929445.jpg" />
    </div>
    <div class="news-HCont">
      <nav class="navbar navbar-expand-lg">
        <ul class="navbar-nav">
          <li class="nav-item" v-bind:class="!soluType ? 'active' : ''">
            <a class="nav-link" href="#/solution">全部</a>
          </li>
          <li class="nav-item" v-for="(item,index) in solution_type" :key="index" v-bind:class="soluType == item.soluTypeId ? 'active' : ''">
            <router-link
              class="nav-link"
              :to="{ path: '/solution?solu_type=' + item.soluTypeId }"
              >{{ item.soluTypeName }}</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
    <div class="news-cont container">
      <div class="news-CL col-md-9">
        <ul class="cnews-list" v-if="solutionList.length>0">
          <li v-for="(solution, index) in solutionList" :key="index">
            <h4>
              <router-link
                target="_blank"
                :to="{
                  path: 'solutiondetails',
                  query: { id: solution.soluId },
                }"
                >{{ solution.soluTitleLv1 }}</router-link
              >
            </h4>
            <p class="desc">
              {{ solution.soluContent | filtersText }}
            </p>
            <p class="info">
              <span>{{ solution.createTime | filtersData }}</span>
              <b-icon icon="eye-fill" width="18" height="18"></b-icon>
              <span>{{ solution.hits }}</span>
            </p>
          </li>
        </ul>
        <div class="noData" v-else>
          暂无数据
        </div>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-if="currentPage > 1" @click="prevPage()">
              <span class="page-link">上一页</span>
            </li>
            <li class="page-item disabled" v-else>
              <span class="page-link">上一页</span>
            </li>
            <li
              class="page-item"
              v-for="index of totalPage"
              :key="index"
              :class="{ active: currentPage == index }"
              @click="pageClick(index)"
            >
              <span class="page-link">{{ index }}</span>
            </li>
            <li class="page-item disabled" v-if="currentPage >= totalPage ">
              <span class="page-link" aria-label="Next">下一页</span>
            </li>
            <li class="page-item" v-else @click="nextPage()">
              <span class="page-link" aria-label="Next">下一页</span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="news-CR col-md-3">
        <div class="form-group" style="display: none">
          <div class="input-search">
            <button type="submit" class="input-search-btn butbg">
              <b-icon
                icon="search"
                width="18"
                height="18"
                @click="searchSolution()"
              ></b-icon>
            </button>
            <input
              type="text"
              class="form-control"
              name="searchword"
              placeholder="search"
              v-model="keyword"
            />
          </div>
        </div>
        <div class="left-menu">
          <ul class="list-group">
            <li class="list-group-item">
              <a href="#/solution" v-bind:class="!soluType ? 'active' : ''"
                >全部解决方案</a
              >
            </li>
            <li class="list-group-item" v-for="(item,index) in solution_type" :key="index" >
              <router-link
                :to="{ path: '/solution?solu_type=' + item.soluTypeId }" v-bind:class="soluType == item.soluTypeId ? 'active' : ''"
                >{{ item.soluTypeName }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="recommend">
          <h5>为你推荐</h5>
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(recommend, index) in recommendList"
              :key="index"
            >
              <router-link :to="{
                  path: '/solutiondetails',
                  query: { id: recommend.soluId },
                }">{{
                recommend.soluTitleLv1
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Solution",
  data() {
    return {
      //解决方案数据
      solutionList: [],
      //为你推荐
      recommendList: [],
      //解决方案类型
      soluType: null,
      keyword: "",
      //解决方案菜单
      solution_type: [],  
      //每页显示10条数据
      pageSize: 10,
      //当前页
      currentPage: 1,
      //总页数
      totalPage: 1,
      //新闻总条数
      totalNum: 0,
    };
  },
  mounted() {
    var _this = this;
    //获取类型
    _this.soluType = this.$route.query.solu_type;
    //获取解决方案信息
    _this.getSolution();
  },
  methods: {
    getSolution() {
      var _this = this;
      var params = {};
      // params["pageSize"] = 1;
      params["pageNum"] = _this.currentPage;
      params["pageSize"] = _this.pageSize;
      if (_this.soluType != undefined) {
        params["soluType"] = _this.soluType;
      }
      //ajax获取列表信息
      _this.$axios
        .get("/system/solution/websiteList", {
          params: params,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            _this.solutionList = response.data.data.solution.rows;
            _this.totalNum = response.data.data.solution.total;
            _this.recommendList = response.data.data.recommend;
            _this.solution_type = response.data.data.solution_type;
            //获取总页数
            _this.getPage();
          }
        });
    },
    getPage() {
      var _this = this;
      //分页
      _this.totalPage = Math.ceil(_this.totalNum / _this.pageSize);
    },
    //分页
    pageClick(index) {
      this.currentPage = index;
      this.getSolution();
    },
    //上一页
    prevPage() {
      this.currentPage = this.currentPage - 1;
      this.getSolution();
    },
    //下一页
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.getSolution();
    },
    //搜索解决方案
    searchSolution() {
      var _this = this;
      this.$router.push({
        path: "/solutionsearch?keyword=" + _this.keyword,
      });
    },
  },
  watch: {
    //监听路由改变
    $route(to) {
      var _this = this;
      //获取类型
      _this.soluType = to.query.solu_type;
      _this.currentPage = 1;
      //获取信息
      _this.getSolution();
    },
  },
  filters: {
    //提纯富文本中的文字并显示前150的字符
    filtersText(val) {
      if (val != null && val != "") {
        var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = val.replace(re1, "");
        return msg.substr(0, 150);
      } else {
        return "";
      }
    },
    filtersData(val) {
      if (val != null && val != "") {
        return val.substr(0, 10);
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.news-bj {
  background-color: #f2f2f2 !important;
}

.news-HImg img {
  width: 100%;
  height: 200px;
}

.news-HCont {
  width: 100%;
  display: block;
  background-color: #ffffff;
}

.news-HCont .navbar-nav .active > .nav-link {
  color: #00c9a1 !important;
}

.news-HCont .navbar {
  border-bottom: 1px solid #e5e7ea;
}

.news-HCont .navbar-nav {
  margin: 0px auto;
  padding: 15px 0px;
}

.news-HCont .navbar-nav a {
  color: #555555 !important;
  font-size: 16px;
}

.navbar-nav .dropdown-item:hover a {
  color: #00c9a1 !important;
}

.news-HCont .dropdown-menu {
  background-color: #ffffff !important;
  line-height: 30px;
}

.news-cont {
  display: flex;
  padding: 30px 0px;
}

.news-CL,
.news-CR {
  background-color: #ffffff;
  padding: 15px 0px;
}

.news-CR {
  padding: 20px;
  margin: 0px 0px 0px 20px;
}

.cnews-list {
  padding: 25px 25px 0px 25px;
}

.cnews-list li {
  border-bottom: 1px solid #e5e7ea;
  margin: 20px 0px;
}

.cnews-list li a {
  color: #555555;
}

.cnews-list li a:hover {
  text-decoration: none;
  color: #00c9a1;
}

.cnews-list li .desc {
  color: #999999;
  font-size: 16px;
  line-height: 25px;
}

.cnews-list li .info span,
.cnews-list li .info svg {
  color: #999999;
  margin-right: 10px;
}

.pagination li a {
  color: #76838f;
}

.pagination .disabled .page-link {
  color: #ccd5db;
}

.page-item{
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: #00c9a1;
  border-color: #00c9a1;
  color: #ffffff;
}

.input-search .input-search-btn {
  position: absolute;
  top: 25px;
  right: 30px;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}

.input-search .form-control {
  border-radius: 2rem;
}

.input-search .form-control:focus {
  border: 1px solid#00c9a1;
  box-shadow: none;
}

.left-menu,
.recommend {
  border-top: 1px solid #f0f2f5;
  margin: 20px 0px;
  padding: 20px 0px 0px 0px;
}

.left-menu .list-group,
.left-menu .list-group .list-group-item {
  border: none;
  padding: 5px 0px;
  font-size: 16px;
}

.left-menu .list-group .list-group-item .active {
  color: #00c9a1;
}

.recommend h5 {
  color: #555555;
  font-weight: 300;
}

.recommend a,
.left-menu a {
  color: #555555;
}

.recommend a:hover,
.left-menu a:hover {
  text-decoration: none;
  color: #00c9a1;
}
.noData{
  padding: 25px;
  text-align: center;
  color: #ccd5db;
  font-size: 16px;
}
</style>
